import React from 'react';
import './category.css';

const Category = ({ href, src, text, icon, bgColor }) => {
    return (
        <a href={href} className="category_box">
            <img src={src} alt={text} className="category_img" />
            <div className="hover_box" style={{ background: bgColor }}>
                <div className="text">
                    {text}
                    <span className="category_icon">
                        <img src={icon} alt={text} />
                    </span>
                </div>
            </div>
        </a>
    );
};

export default Category;
