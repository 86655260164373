import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const env = process.env.NODE_ENV;
const variant = process.env.REACT_APP_BUILD_VARIANT || 'myshoppingfeed';

const Apps = {
    myshoppingfeed: 'my-shopping-feed',
    purchasepioneer: 'purchase-pioneer',
};

const AnalyticsConfig = {
    development: {
        google: {
            trackingId: 'dev-tracking-id',
        },
    },
    production: {
        google: {
            trackingId: 'UA-150259946-4',
        },
    },
};

const trackingId = AnalyticsConfig[env]?.google?.trackingId;

const analytics = Analytics({
    app: Apps[variant],
    plugins: [
        trackingId &&
            googleAnalytics({
                debug: env === 'development',
                trackingId,
            }),
    ].filter(Boolean),
});

export default analytics;
