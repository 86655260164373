import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '@/containers/Header';
import Footer from '@/containers/Footer';
import ContactForm from '@/components/ContactForm';
import { useAppState } from '@/context/AppContext';

import './contact.css';

const Contact = () => {
    const { variant } = useAppState();

    return (
        <div className="contact">
            <Helmet>
                <title>{variant.title} | Contact</title>
            </Helmet>
            <Header compact />
            <section className="content_section content_section2 contact_section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Contact Us</h2>
                            <p>If you wish to contact us via email please write to:</p>
                            <p>{variant.supportEmail}</p>
                            <p>or by mail at:</p>
                            <p>43 West 29th Street, 4th Floor, New York, NY 10031</p>
                            <p>or use the contact form below:</p>
                            <ContactForm site={variant.id} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Contact;
