import React, { useEffect } from 'react';
import { Router, useLocation } from '@reach/router';
import { useAnalytics } from 'use-analytics';
import Home from '@/pages/Home';
import Categories from '@/pages/Categories';
import Search from '@/pages/Search';
import Articles from '@/pages/Articles';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import TermsOfService from '@/pages/TermsOfService';
import About from '@/pages/About';
import Contact from '@/pages/Contact';
import Faq from '@/pages/Faq';
import { useAppDispatch } from '@/context/AppContext';

const App = () => {
    const location = useLocation();
    const { page } = useAnalytics();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const { search, href } = location;
        const searchParams = new URLSearchParams(search);
        searchParams.delete('q');
        const sanitizedSearch = searchParams.toString().length ? `?${searchParams}` : '';
        const sanitizedHref = `${href.split('?')[0]}${sanitizedSearch}`;
        page({ search: sanitizedSearch, url: sanitizedHref });
        dispatch({ type: 'setSource', payload: searchParams.get('cf') });
    }, [location, page, dispatch]);

    return (
        <Router>
            <Home path="/" />
            <Categories path="/categories/:category" />
            <Search path="/search" />
            <Articles path="/articles" />
            <PrivacyPolicy path="/privacy-policy" />
            <TermsOfService path="/terms-of-service" />
            <About path="/about" />
            <Contact path="/contact" />
            <Faq path="/faq" />
        </Router>
    );
};

export default App;
