import React from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Footer from '@/containers/Footer';
import Header from '@/containers/Header';
import { useAppState } from '@/context/AppContext';
import { simpleTemplate } from '@/utils/template';

import './privacy-policy.css';
import privacyPolicyContent from './privacy-policy.md';

const PrivacyPolicy = () => {
    const { variant } = useAppState();
    const remarkPlugins = [{ remarkGfm }].filter(Boolean);

    return (
        <>
            <Helmet>
                <title>{variant.title} | Privacy Policy</title>
            </Helmet>
            <Header compact />
            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ReactMarkdown remarkPlugins={remarkPlugins}>
                                {simpleTemplate(privacyPolicyContent, variant)}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
