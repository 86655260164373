const { NODE_ENV, REACT_APP_ENV, REACT_APP_BUILD_VARIANT = 'myshoppingfeed' } = process.env;

let apiEndpoint;
let searchApiEndpoint;

if (REACT_APP_BUILD_VARIANT === 'myshoppingfeed') {
    if (NODE_ENV === 'production') {
        apiEndpoint = 'https://www.myshoppingfeed.com/production';
        searchApiEndpoint = 'https://api.hkhcq.com';
    } else {
        apiEndpoint = 'https://www.myshoppingfeed.com/production';
        searchApiEndpoint = 'https://api.hkhcq.com';
    }

    if (REACT_APP_ENV === 'local') {
        apiEndpoint = 'http://localhost:4000';
        searchApiEndpoint = 'http://localhost:4000';
    }
} else if (REACT_APP_BUILD_VARIANT === 'purchasepioneer') {
    if (NODE_ENV === 'production') {
        apiEndpoint = 'https://www.purchasepioneer.com/production';
        searchApiEndpoint = 'https://api.hkhcq.com';
    } else {
        apiEndpoint = 'https://www.purchasepioneer.com/production';
        searchApiEndpoint = 'https://api.hkhcq.com';
    }

    if (REACT_APP_ENV === 'local') {
        apiEndpoint = 'http://localhost:4000';
        searchApiEndpoint = 'http://localhost:4000';
    }
}

const config = {
    clientId: 't7wN9T7QTtc1Gykcvk1hAC',
    campaignId: 'stdWENUPxwR9K6D3ro4bk8',
    authToken: 'sTb5NybTCfy54SrokyZmap',
    apiEndpoint,
    searchApiEndpoint,
};

export default config;
