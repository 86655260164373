import category1 from '@/assets/images/category1.jpg';
import category2 from '@/assets/images/category2.jpg';
import category3 from '@/assets/images/category3.jpg';
import category4 from '@/assets/images/category4.jpg';
import category5 from '@/assets/images/category5.jpg';
import category6 from '@/assets/images/category6.jpg';
import category7 from '@/assets/images/category7.jpg';
import category8 from '@/assets/images/category8.jpg';

import icon1 from '@/assets/images/icon_1.svg';
import icon2 from '@/assets/images/icon_2.svg';
import icon3 from '@/assets/images/icon_3.svg';
import icon4 from '@/assets/images/icon_4.svg';
import icon5 from '@/assets/images/icon_5.svg';
import icon6 from '@/assets/images/icon_6.svg';
import icon7 from '@/assets/images/icon_7.svg';
import icon8 from '@/assets/images/icon_8.svg';

const categories = [
    {
        id: 'mens-fashion',
        src: category1,
        href: '/categories/mens-fashion',
        text: "Men's Fashion",
        searchText: 'mens',
        icon: icon1,
        bgColor: '#828ce0c4',
    },
    {
        id: 'womens-fashion',
        src: category2,
        href: '/categories/womens-fashion',
        text: "Women's Fashion",
        searchText: 'womens',
        icon: icon2,
        bgColor: '#f15c69b8',
    },
    {
        id: 'sports-and-outdoor',
        src: category3,
        href: '/categories/sports-and-outdoor',
        text: 'Sports and Outdoor',
        searchText: 'sports',
        icon: icon3,
        bgColor: '#fdaf3abf',
    },
    {
        id: 'baby-care',
        src: category4,
        href: '/categories/baby-care',
        text: 'Baby Care',
        searchText: 'toddler',
        icon: icon4,
        bgColor: '#3acafabd',
    },
    {
        id: 'diy-and-decorating',
        src: category5,
        href: '/categories/diy-and-decorating',
        text: 'DIY and Decorating',
        searchText: 'tools',
        icon: icon5,
        bgColor: '#d939b6b8',
    },
    {
        id: 'electronics',
        src: category6,
        href: '/categories/electronics',
        text: 'Electronics',
        searchText: 'electronics',
        icon: icon6,
        bgColor: '#0eaec3b8',
    },
    { id: 'books', src: category7, href: '/categories/books', text: 'Books', icon: icon7, bgColor: '#999999b8' },
    {
        id: 'other',
        src: category8,
        href: '/categories/other',
        text: 'Other',
        searchText: 'toys',
        icon: icon8,
        bgColor: '#1d64cfb3',
    },
];
export default categories;
