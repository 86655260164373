import { useEffect } from 'react';
import config from '@/config';
import { parseProducts } from '@/hooks/helpers';
import { dedupeProducts } from './helpers';
import { useAppState } from '@/context/AppContext';

export const SIZE = 25;
export const MAX_PAGE_SIZE = 25;

export const findProduct = async (searchTerm, from = 0, tag) => {
    const data = {
        client_id: config.clientId,
        campaign_id: config.campaignId,
        text: searchTerm,
        size: SIZE,
        from,
        country_code: 'us',
    };
    const res = await fetch(`${config.searchApiEndpoint}/search`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${config.authToken}`,
        },
        body: JSON.stringify(data),
    }).then(res => res.json());

    return { products: parseProducts(res, tag), from: from + SIZE };
};

const zipLongest = arrays => {
    const length = Math.max(...arrays.map(arr => arr.length));
    return Array.from({ length }, (value, index) =>
        arrays.map(array => (array.length - 1 >= index ? array[index] : undefined))
    );
};

export const useSearchProducts = (searchTerms, setProducts) => {
    const { variant } = useAppState();

    useEffect(() => {
        const func = async () => {
            if (!searchTerms) {
                setProducts([]);
                return;
            }
            const dataFetchers = searchTerms.map(searchTerm => findProduct(searchTerm, 0, variant.tag));
            const productSearchResultsData = await Promise.all(dataFetchers);
            const productSearchResults = productSearchResultsData.map(r => r.products);
            let zipLongest1 = zipLongest(productSearchResults);
            const allProducts = zipLongest1.flat(2).filter(Boolean);
            const deduplicatedProducts = dedupeProducts([], allProducts);
            setProducts(deduplicatedProducts);
        };

        func();
    }, [setProducts, searchTerms, variant.tag]);
};
