import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from '@reach/router';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Footer from '@/containers/Footer';
import Category from '@/containers/Category';
import Products from '@/containers/Products';
import Header from '@/containers/Header';
import { useAppState } from '@/context/AppContext';
import { useSearchCategories, findProduct, SIZE, MAX_PAGE_SIZE } from '@/hooks/categories';
import { dedupeProducts } from '@/hooks/helpers';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';

import './categories.css';

const Categories = () => {
    const { category } = useParams();
    const { variant, categories: popularCategories } = useAppState();
    const categoryData = popularCategories.find(x => x.id === category) ?? {};
    const categoryText = categoryData.text;
    const categorySearchText = categoryData.searchText || categoryData.text;

    const [products, setProducts] = useState([]);
    useSearchCategories(categorySearchText, setProducts);
    const [from, setFrom] = useState(SIZE);

    const onClickLoadMoreSearch = async isScrollEvent => {
        if ((isScrollEvent || !loadingMore) && from / SIZE < MAX_PAGE_SIZE) {
            setLoadingMore(true);
            const productResults = await findProduct(categorySearchText, from);
            setFrom(productResults.from);
            setProducts(dedupeProducts(products, productResults.products));
            setLoadingMore(false);
        }
    };

    const [loadingMore, setLoadingMore] = useInfiniteScroll(onClickLoadMoreSearch);

    return (
        <>
            <Helmet>
                <title>{variant.title} | Categories</title>
            </Helmet>
            <Header compact />
            <div id="amzn-assoc-ad-c92762b4-eb5b-4e4b-a7ec-45223dfee405"></div>
            <script
                async
                src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=c92762b4-eb5b-4e4b-a7ec-45223dfee405"
            ></script>
            <section className="slider_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="main_categories">
                                <CarouselProvider
                                    naturalSlideWidth={200}
                                    naturalSlideHeight={152}
                                    isIntrinsicHeight
                                    visibleSlides={5.5}
                                    totalSlides={popularCategories.length}
                                >
                                    <Slider classNameTrayWrap="category_slider">
                                        {popularCategories.map((category, i) => (
                                            <Slide index={i} key={i} innerClassName="slide">
                                                <Category
                                                    src={category.src}
                                                    text={category.text}
                                                    icon={category.icon}
                                                    href={category.href}
                                                    bgColor={category.bgColor}
                                                />
                                            </Slide>
                                        ))}
                                    </Slider>
                                </CarouselProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_section">
                <div className="container">
                    <div className="row">
                        <Products products={products} title={`Top products for ${categoryText}`} />
                    </div>
                    {from / SIZE < MAX_PAGE_SIZE && (
                        <div className="row" onClick={onClickLoadMoreSearch}>
                            <div className="col-12">
                                <div className="more">{loadingMore ? 'Loading...' : 'Load More >'}</div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Categories;
