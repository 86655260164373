import { useState, useEffect } from 'react';
import config from '@/config';
import { truncate } from '@/hooks/helpers';

export const useSearchSuggestions = searchTerm => {
    const [stateSuggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (!searchTerm) {
            setSuggestions([]);
            return;
        }

        const getSuggestions = async searchTerm => {
            const { apiEndpoint, campaignId, clientId, authToken } = config;
            const res = await fetch(`${apiEndpoint}/related-products/${clientId}/${campaignId}?q=${searchTerm}`, {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            }).then(res => res.json());

            setSuggestions(res.map(res => truncate(res.name, 50, true)));
        };
        getSuggestions(searchTerm);
    }, [setSuggestions, searchTerm]);

    return stateSuggestions;
};
