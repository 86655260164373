import React from 'react';

import Product from '../Product';
import './products.css';

const Products = ({ products, title }) => {
    return (
        <div className="col-12">
            <div className="heading">{title}</div>
            <div className="products">
                {products.map((product, i) => (
                    <div className="product" key={i}>
                        <Product
                            name={product.name}
                            isAd={product.isAd}
                            price={product.price}
                            src={product.src}
                            sale={product.sale}
                            link={product.link}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
