import React from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

import Header from '@/containers/Header';
import Footer from '@/containers/Footer';
import { useAppState } from '@/context/AppContext';
import { simpleTemplate } from '@/utils/template';

import './terms-of-service.css';
import termsOfServiceContent from './terms-of-service.md';

const TermsOfService = () => {
    const { variant } = useAppState();
    return (
        <>
            <Helmet>
                <title>{variant.title} | Terms Of Service</title>
            </Helmet>
            <Header compact />
            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ReactMarkdown>{simpleTemplate(termsOfServiceContent, variant)}</ReactMarkdown>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default TermsOfService;
