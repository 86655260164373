import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useAnalytics } from 'use-analytics';

import { useAppState } from '@/context/AppContext';
import { hrefWithSource } from '@/utils/href';
import Footer from '@/containers/Footer';
import Products from '@/containers/Products';
import Header from '@/containers/Header';
import { dedupeProducts } from '@/hooks/helpers';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { useSearchProducts, findProduct, SIZE, MAX_PAGE_SIZE } from '@/hooks/products';
import { useSearchSuggestions } from '@/hooks/suggest';

import './search.css';
import closeImage from './images/close.svg';

const Search = () => {
    const { track } = useAnalytics();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const searchTerm = urlParams.get('q');
    const { variant, source } = useAppState();

    const [filteredProducts, setProducts] = useState([]);
    useSearchProducts([searchTerm], setProducts);
    const [from, setFrom] = useState(SIZE);

    const onClickLoadMoreSearch = async isScrollEvent => {
        if ((isScrollEvent || !loadingMore) && from / SIZE < MAX_PAGE_SIZE) {
            setLoadingMore(true);
            const productResults = await findProduct(searchTerm, from);
            setFrom(productResults.from);
            setProducts(dedupeProducts(filteredProducts, productResults.products));
            setLoadingMore(false);
        }
    };

    const [loadingMore, setLoadingMore] = useInfiniteScroll(onClickLoadMoreSearch, 3000);

    const brands = [...new Set(filteredProducts.map(({ brand }) => brand))];

    const minPrice = filteredProducts.reduce((acc, curr) => {
        const price = parseInt(curr.price.slice(1));
        return price < acc ? price : acc;
    }, 0);

    const maxPrice = filteredProducts.reduce((acc, curr) => {
        const price = parseInt(curr.price.slice(1));
        return price > acc ? price : acc;
    }, 0);

    const suggestions = useSearchSuggestions(searchTerm);

    const filterForm = useRef();
    const brandFilter = useRef();
    const priceFilter = useRef();

    const filterProducts = e => {
        e.preventDefault();
        const products = filteredProducts.filter(product => {
            const isWithinPrice = parseInt(product.price.slice(1)) <= priceFilter.current.value;
            const isBrand = product.brand.includes(brandFilter.current.value);
            return brandFilter.current.value !== 'Brand' ? isWithinPrice && isBrand : isWithinPrice;
        });

        const filterType = brandFilter.current.value === 'Brand' ? 'priceFilter' : 'priceFilter & brandFilter';

        track('filterSearch', {
            category: 'Search',
            label: filterType,
        });
        setProducts(products);
    };

    const resetFilters = e => {
        setProducts(filteredProducts);
    };

    return (
        <>
            <Helmet>
                <title>{variant.title} | Search</title>
            </Helmet>
            <Header compact search={searchTerm} />
            <section className="search_section">
                <div className="container">
                    <div className="row m_row">
                        <div className="col-12 col-md-3 m_col order">
                            <form onSubmit={filterProducts} onReset={resetFilters} ref={filterForm}>
                                <div className="heading">
                                    Filters{' '}
                                    <span className="close">
                                        <img src={closeImage} alt="#" />
                                    </span>
                                </div>
                                <div className="white_box">
                                    <div className="head">Price</div>
                                    <div className="slider_value">
                                        <span className="min">${minPrice}</span>
                                        <span className="max">${maxPrice}</span>
                                    </div>
                                    <div className="slidecontainer">
                                        <input
                                            type="range"
                                            min={minPrice}
                                            max={maxPrice}
                                            ref={priceFilter}
                                            defaultValue={Math.round(maxPrice / 2)}
                                            className="slider"
                                            id="myRange"
                                        />
                                    </div>
                                </div>
                                <select ref={brandFilter} className="selectpicker">
                                    <option>Brand</option>
                                    {brands.map((brand, i) => (
                                        <option key={i}>{brand}</option>
                                    ))}
                                </select>
                                <div className="form-group">
                                    <button className="btn_red" type="submit">
                                        Apply
                                    </button>
                                    <input type="reset" className="reset" defaultValue="Reset" />
                                </div>
                                <div className="white_box">
                                    <div className="head">Explore related items</div>
                                    <ul>
                                        {suggestions.map((suggestion, i) => (
                                            <li key={i} className="related-item">
                                                <a href={hrefWithSource(`/search?q=${suggestion}`, source)}>
                                                    {suggestion}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </form>
                        </div>

                        <section className="content_section col-12 col-md-9 m_col">
                            <div className="container">
                                <div className="row">
                                    <Products products={filteredProducts} title={`Top products for ${searchTerm}`} />
                                </div>
                                {from / SIZE < MAX_PAGE_SIZE && (
                                    <div className="row" onClick={onClickLoadMoreSearch}>
                                        <div className="col-12">
                                            <div className="more">{loadingMore ? 'Loading...' : 'Load More >'}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Search;
