export const SOURCE_KEY_EXTERNAL = 'source';
export const SOURCE_KEY_INTERNAL = 'cf';

export function linkWithSource(sourceUrl, source) {
    try {
        if (!source) {
            return sourceUrl;
        }

        const url = new URL(sourceUrl);
        url.searchParams.set(SOURCE_KEY_EXTERNAL, source);
        return url.toString();
    } catch (e) {
        return sourceUrl;
    }
}

export function hrefWithSource(href = '/', source) {
    try {
        if (!source) {
            return href;
        }

        const url = new URL(href, window.location.origin);
        url.searchParams.set(SOURCE_KEY_INTERNAL, source);
        return `${url.pathname}${url.search}`;
    } catch (e) {
        return href;
    }
}
