import React from 'react';

import './product.css';
import arrowRight from './images/arrow_right.svg';
import arrowRightWhite from './images/arrow_right_white.svg';

const Product = ({ name, src, price, sale, isAd = false, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="product_box">
            <div className="top">
                <div className="price">
                    {price}
                    {isAd && <span>Ad</span>}
                </div>
                <img loading="lazy" src={src} alt="" className="product_img" />
                <div className="name">{name}</div>
                {sale && <div className="sale">{sale}</div>}
            </div>
            <div className="btn_price">
                Show Price
                <img src={arrowRight} alt="#" className="green" />
                <img src={arrowRightWhite} alt="#" className="white" />
            </div>
        </a>
    );
};

export default Product;
