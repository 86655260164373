import { useState, useEffect } from 'react';
import config from '@/config';

export const useTrendingStores = () => {
    const [stateTrendingStores, setTrendingStores] = useState([]);

    useEffect(() => {
        const findStores = async searchTerm => {
            const { campaignId, searchApiEndpoint } = config;

            const res = await fetch(`${searchApiEndpoint}/multi/${campaignId}?forcedType=quicklinks&size=3`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${config.authToken}`,
                },
            }).then(res => res.json());

            const trendingStores = res.data.map(trendingStore => ({
                src: trendingStore.creatives?.logosqr_180,
                link: trendingStore.link,
            }));

            setTrendingStores(trendingStores);
        };
        findStores();
    }, [setTrendingStores]);

    return stateTrendingStores;
};
