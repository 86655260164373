export const parseProducts = (products, tag) =>
    products.map(product => {
        const [baseUrl, query] = product.link.split('?');

        const parsedQuery = new URLSearchParams(query);

        const innerLink = decodeURIComponent(parsedQuery.get('u'));
        const [innerLinkBaseUrl, innerLinkQuery] = innerLink.split('?');

        const parsedInnerLinkQuery = new URLSearchParams(innerLinkQuery);
        parsedInnerLinkQuery.set('tag', tag);

        const modifiedInnerLink = `${innerLinkBaseUrl}?${parsedInnerLinkQuery}`;

        parsedQuery.set('u', modifiedInnerLink);

        const price = !!product.price ? `$${product.price}` : '';
        const creative = product.creatives?.product || product.creatives?.logosqr || '';

        return {
            name: truncate(product.name, 50, true),
            price,
            src: creative.replace(/(.*fetch\/)(.*)(\/.*)/, '$1w_512,q_100$3').replace('h_48,w_48', 'w_512'),
            link: `${baseUrl}?${parsedQuery}`,
            brand: product.rootDomain,
        };
    });

export const truncate = (str, n, useWordBoundary) => {
    if (str.length <= n) {
        return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString;
};

export const dedupeProducts = (existingProducts, newProducts) => {
    const fullProductList = existingProducts.concat(newProducts);
    const filteredProducts = [];
    const seenNames = new Set();
    for (const product of fullProductList) {
        if (!product || !product.name) {
            continue;
        }
        if (!seenNames.has(product.name)) {
            filteredProducts.push(product);
            seenNames.add(product.name);
        }
    }
    return filteredProducts;
};
