import React from 'react';

import Category from '@/components/Category';
import { hrefWithSource } from '@/utils/href';
import { useAppState } from '@/context/AppContext';

export default function CategoryContainer(props) {
    const { source } = useAppState();
    const href = hrefWithSource(props.href, source);

    return <Category {...props} href={href} />;
}
