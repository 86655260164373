import { useState, useEffect } from 'react';
import config from '@/config';
import { parseProducts } from '@/hooks/helpers';
import { useAppState } from '@/context/AppContext';

export const SIZE = 40;
export const MAX_PAGE_SIZE = 50;

export const useGetCategories = () => {
    const [stateCategories, setCategories] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            const { apiEndpoint, clientId, campaignId, authToken } = config;
            const res = await fetch(`${apiEndpoint}/categories/${clientId}/${campaignId}?country_code=us`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            }).then(res => res.json());

            const categories = res.map(c => {
                const slug = c.toLowerCase().replaceAll(' ', '-', 'g');
                return {
                    id: slug,
                    text: c,
                    href: `/categories/${slug}`,
                };
            });
            setCategories(categories);
        };
        getCategories();
    }, [setCategories]);

    return stateCategories;
};

export const findProduct = async (searchTerm, from = 0, tag) => {
    const { searchApiEndpoint, clientId, campaignId, authToken } = config;
    const res = await fetch(
        `${searchApiEndpoint}/category-search/${clientId}/${campaignId}?q=${searchTerm}&country_code=us&size=${SIZE}&from=${from}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        }
    ).then(res => res.json());

    return { products: parseProducts(res, tag), from: from + SIZE };
};

export const useSearchCategories = (searchTerm, setProducts) => {
    const { variant } = useAppState();

    useEffect(() => {
        const func = async () => {
            if (!searchTerm) {
                setProducts([]);
                return;
            }

            const { products } = await findProduct(searchTerm, 0, variant.tag);
            setProducts(products);
        };

        func();
    }, [setProducts, searchTerm, variant.tag]);
};
