import React from 'react';
import classnames from 'classnames';

import { hrefWithSource, SOURCE_KEY_INTERNAL } from '@/utils/href';
import { useAppState } from '@/context/AppContext';

import './header.css';
import logo from './images/logo.svg';

const Header = ({ compact, search }) => {
    const { source, variant } = useAppState();

    const HeroSection = (
        <div className="row justify-content-center">
            <div className="col-12 col-md-6">
                <h1>
                    Jumpstart <br />
                    your shopping
                </h1>
                <form action="/search">
                    <input
                        type="text"
                        name="q"
                        defaultValue={search}
                        placeholder="brands, products, services"
                        className="form-control"
                    />
                    <button className="btn_submit" type="submit">
                        Search
                    </button>
                    <input type="hidden" name={SOURCE_KEY_INTERNAL} value={source} />
                </form>
                <p>Trusted by over 5,000,000 users every month</p>
            </div>
        </div>
    );

    const CompactSection = (
        <div className="col-12 col-md-9">
            <form action="/search">
                <input
                    type="text"
                    name="q"
                    defaultValue={search}
                    placeholder="brands, products, services"
                    className="form-control"
                />
                <button className="btn_submit" type="submit">
                    Search
                </button>
                <input type="hidden" name={SOURCE_KEY_INTERNAL} value={source} />
            </form>
        </div>
    );

    return (
        <section className={classnames('header', { header2: compact })}>
            <div
                className={classnames({
                    'header-bg': !compact,
                    'no-img': variant.showHeaderImage === false,
                })}
            >
                <div className="container">
                    <div className="row">
                        <div className={classnames('col-12', { 'col-md-3': compact })}>
                            <a href={hrefWithSource('/', source)}>
                                {variant.showLogo !== false && <img src={logo} alt="#" className="logo" />}
                            </a>
                        </div>
                        {compact && CompactSection}
                    </div>
                    {!compact && HeroSection}
                </div>
            </div>
        </section>
    );
};

export default Header;
