export function simpleTemplate(targetStr, vars) {
    let templated = targetStr;

    Object.entries(vars).forEach(([k, v]) => {
        const re = new RegExp(`{{${k}}}`, 'gi');
        templated = templated.replace(re, v);
    });

    return templated;
}
