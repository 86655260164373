const Variants = {
    myshoppingfeed: {
        id: 'myshoppingfeed',
        title: 'MyShoppingFeed',
        url: 'https://www.myshoppingfeed.com',
        humanUrl: 'Myshoppingfeed.com',
        supportEmail: 'support@myshoppingfeed.com',
        theme: {
            'color-primary': '#1dcf96',
        },
        tag: 'myshoppingfeed-20',
    },
    purchasepioneer: {
        id: 'purchasepioneer',
        title: 'PurchasePioneer',
        showLogo: false,
        url: 'https://www.purchasepioneer.com',
        humanUrl: 'PurchasePioneer.com',
        supportEmail: 'support@purchasepioneer.com',
        showHeaderImage: false,
        theme: {
            'color-primary': '#356872',
        },
        tag: 'purchasepioneer-20',
    },
};

export default Variants;
