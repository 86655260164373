import React, { useState } from 'react';
import classnames from 'classnames';
import './contact-form.css';

const ContactForm = ({ site }) => {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        });
        if (ok) {
            form.reset();
        }
    };

    const handleOnSubmit = async e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });

        try {
            await fetch('https://formspree.io/f/xleonqkd', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                },
                body: new FormData(form),
            });
            handleServerResponse(true, "Thanks we've received your submission!", form);
        } catch (err) {
            console.error(err);
            handleServerResponse(false, 'Sorry something went wrong!', form);
        }
    };

    return (
        <form className="contact-form" onSubmit={handleOnSubmit}>
            {site && <input type="hidden" name="site" value={site} />}
            <label htmlFor="name">Name:</label>
            <input id="name" type="text" name="name" required />
            <label htmlFor="email">Email:</label>
            <input id="email" type="email" name="email" required />
            <label htmlFor="message">Message:</label>
            <textarea id="message" rows="5" name="message"></textarea>
            <button type="submit" disabled={serverState.submitting}>
                Submit
            </button>
            {serverState.status && (
                <h4 className={classnames({ 'error-message': !serverState.status.ok })}>{serverState.status.msg}</h4>
            )}
        </form>
    );
};

export default ContactForm;
