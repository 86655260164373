import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback, threshold = 2000) => {
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (
                window.innerHeight + document.documentElement.scrollTop + threshold <=
                    document.documentElement.offsetHeight ||
                loadingMore
            )
                return;
            setLoadingMore(true);
            callback(true);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadingMore, setLoadingMore, threshold, callback]);

    return [loadingMore, setLoadingMore];
};

export default useInfiniteScroll;
