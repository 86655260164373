import React from 'react';
import './store-link.css';

const StoreLink = ({ href, src, alt = 'store link' }) => {
    return (
        <div className="store-link">
            <a href={href} target="_blank" rel="noopener noreferrer">
                <img src={src} alt={alt} />
            </a>
        </div>
    );
};

export default StoreLink;
