import React from 'react';
import './article-section.css';

const ArticleSection = ({ title, content, index, src }) => {
    return (
        <section className="how_section" style={{ background: `url(${src})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="head">{title}</div>
                        <p>{content}</p>
                    </div>
                </div>
                <span className="number">{index}</span>
            </div>
        </section>
    );
};

export default ArticleSection;
