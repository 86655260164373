import React from 'react';

import { hrefWithSource } from '@/utils/href';
import { useAppState } from '@/context/AppContext';

import './footer.css';
import arrowRight from './images/arrow_right.svg';
import arrowDown from './images/arrow_down.svg';
import CookieConsent from 'react-cookie-consent';

const Footer = () => {
    const { source, categories, trendingSearches } = useAppState();
    const categoriesSection = (
        <ul>
            {categories.map((category, i) => (
                <li key={i}>
                    <a href={hrefWithSource(category.href, source)}>{category.text}</a>
                </li>
            ))}
        </ul>
    );

    const general = (
        <ul>
            <li>
                <a href={hrefWithSource('/privacy-policy', source)}>Privacy Policy</a>
            </li>
            <li>
                <a href={hrefWithSource('/terms-of-service', source)}>Terms of Service</a>
            </li>
            <li>
                <a href={hrefWithSource('/about', source)}>About</a>
            </li>
            <li>
                <a href={hrefWithSource('/faq', source)}>FAQ</a>
            </li>
            <li>
                <a href={hrefWithSource('/contact', source)}>Contact</a>
            </li>
        </ul>
    );

    const popularSearches = (
        <ul>
            {trendingSearches.slice(0, 6).map((search, i) => (
                <li key={i}>
                    <a href={hrefWithSource(search.href, source)}>{search.searchText}</a>
                </li>
            ))}
        </ul>
    );

    return (
        <>
            <section className="basement">
                <div className="container">
                    <div className="hidden-xs">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <h4>Categories</h4>
                                {categoriesSection}
                            </div>
                            <div className="col-12 col-md-3">
                                <h4>General</h4>
                                {general}
                            </div>
                        </div>
                    </div>
                    <div className="visible-xs">
                        <div className="row">
                            <div className="col-12">
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header">
                                            <a className="card-link active" data-toggle="collapse" href="#collapseOne">
                                                Categories
                                                <img src={arrowRight} alt="#" className="right" />
                                                <img src={arrowDown} alt="#" className="down" />
                                            </a>
                                        </div>
                                        <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                            <div className="card-body">{categoriesSection}</div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header">
                                            <a
                                                className="collapsed card-link"
                                                data-toggle="collapse"
                                                href="#collapseTwo"
                                            >
                                                General
                                                <img src={arrowRight} alt="#" className="right" />
                                                <img src={arrowDown} alt="#" className="down" />
                                            </a>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                            <div className="card-body">{general}</div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header">
                                            <a
                                                className="collapsed card-link"
                                                data-toggle="collapse"
                                                href="#collapseThree"
                                            >
                                                Popular Searches
                                                <img src={arrowRight} alt="#" className="right" />
                                                <img src={arrowDown} alt="#" className="down" />
                                            </a>
                                        </div>
                                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                                            <div className="card-body">{popularSearches}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>© 2022 All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </footer>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </>
    );
};

export default Footer;
