import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '@/containers/Footer';
import Product from '@/containers/Product';
import Header from '@/containers/Header';
import ArticleSection from '@/components/ArticleSection';
import { useAppState } from '@/context/AppContext';

import './articles.css';
import productImage from './images/product.svg';
import blueBgImage from './images/blue_bg.png';
import greenBgImage from './images/green_bg.png';

const Products = new Array(2)
    .fill([
        {
            name: 'Lenovo Thinkbook 15 Microsoft Store',
            price: '$749.99',
            src: productImage,
        },
        {
            name: 'Lenovo Thinkbook 15 Microsoft Store',
            price: '$749.99',
            isAd: true,
            src: productImage,
            sale: '50%',
        },
    ])
    .flat();

const articleSection1 = {
    title: 'How Strong Is Your Password?',
    content:
        "Your passwords probably have a mix of letters, numbers, and symbols plus at least one capital letter to keep hackers at bay. But for 8 years, the password for the computer controlling the US nuclear-tipped miles was simply ‘00000000’ — at least it's easy to remember in case of a nuclear war breaking out!",
    index: 22,
    src: blueBgImage,
};

const articleSection2 = {
    title: 'The First Computer Mouse Was Made Of Wood',
    content:
        'Computers today are primarily associated with disposable plastic hardware, but when Doug Engelbart invented the first computer mouse in 1964, he chooses to house it in wooden construction.',
    index: 1,
    src: greenBgImage,
};

const Articles = () => {
    const { variant } = useAppState();
    return (
        <>
            <Helmet>
                <title>{variant.title} | Articles</title>
            </Helmet>
            <Header compact />
            <ArticleSection {...articleSection1} />

            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="top_products pb-5">
                                <div className="m_row row">
                                    {Products.map((product, i) => (
                                        <div className="col-6 col-md-3 m_col" key={i}>
                                            <Product
                                                name={product.name}
                                                isAd={product.isAd}
                                                price={product.price}
                                                src={product.src}
                                                sale={product.sale}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ArticleSection {...articleSection2} />

            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="top_products pb-5">
                                <div className="m_row row">
                                    {Products.map((product, i) => (
                                        <div className="col-6 col-md-3 m_col" key={i}>
                                            <Product
                                                name={product.name}
                                                isAd={product.isAd}
                                                price={product.price}
                                                src={product.src}
                                                sale={product.sale}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Articles;
