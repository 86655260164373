import React from 'react';
import categories from '@/context/categories';

export const StateContext = React.createContext();
export const DispatchContext = React.createContext();

const INITIAL_STATE = {
    trendingSearches: [],
    categories,
    source: undefined,
    variant: undefined,
};

function reducer(state, action) {
    switch (action.type) {
        case 'setSource': {
            return { ...state, source: action.payload };
        }
        case 'updateTrendingSearches': {
            return { ...state, trendingSearches: action.payload };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export function AppProvider({ children, initialState = {} }) {
    const [state, dispatch] = React.useReducer(reducer, { ...INITIAL_STATE, ...initialState });

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
}

export function useAppState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider');
    }
    return context;
}

export function useAppDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppProvider');
    }
    return context;
}
