import React from 'react';

import { linkWithSource } from '@/utils/href';
import { useAppState } from '@/context/AppContext';
import Product from '@/components/Product';

export default function ProductContainer(props) {
    const { source } = useAppState();

    const link = linkWithSource(props.link, source);

    return <Product {...props} link={link} />;
}
