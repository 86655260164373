import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '@/containers/Header';
import Footer from '@/containers/Footer';
import { useAppState } from '@/context/AppContext';

import './about.css';

const About = () => {
    const { variant } = useAppState();
    return (
        <>
            <Helmet>
                <title>{variant.title} | About</title>
            </Helmet>
            <Header compact />
            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>About Us</h2> 
                            <p>
                                {variant.humanUrl} is a comparison search engine, helping you find the best products at
                                the best prices while browsing through images that make online shopping exciting. At
                                {variant.humanUrl}, we understand that shopping is a visual journey. We're visual beings
                                and most of us process information best when we can see it. That's why we created a
                                visual product comparison search engine that's easy to use. We offer many brands,
                                product types and price points.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default About;
