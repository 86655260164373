import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '@/containers/Header';
import Footer from '@/containers/Footer';
import { useAppState } from '@/context/AppContext';

import './faq.css';

const Faq = () => {
    const { variant } = useAppState();

    return (
        <>
            <Helmet>
                <title>{variant.title} | FAQ</title>
            </Helmet>
            <Header compact />
            <section className="content_section content_section2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Frequently Asked Questions</h2> 
                            <p>Here are the most popular questions we get asked about {variant.humanUrl}.</p>
                            <h5>
                                <strong>What is {variant.humanUrl}?</strong>
                            </h5>
                            <p>
                                {variant.humanUrl} is an image-based product comparison search engine. Using the site,
                                you get to find the best product that fits your needs at the best price.
                            </p>
                            <h5>
                                <strong>What kind of products and brands can I find here?</strong>
                            </h5>
                            <p>
                                Almost anything you want to shop is available through {variant.humanUrl}. Baby products,
                                furniture, kitchen appliances, toys, home décor, small appliances, sporting goods,
                                computers, shoes, clothing and apparel are only a few examples of the types of products
                                you can find here.
                            </p>
                            <h5>
                                <strong>What's the price range?</strong>
                            </h5>
                            <p>
                                We offer products for every budget. Use the right sidebar to filter the price range and
                                you'll only see products that fit you. Under "Price” use the slider then click on
                                "Apply".
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Faq;
