import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import { linkWithSource } from '@/utils/href';
import { useAppState } from '@/context/AppContext';
import Category from '@/containers/Category';
import Products from '@/containers/Products';
import Footer from '@/containers/Footer';
import Header from '@/containers/Header';
import StoreLink from '@/components/StoreLink';
import { useTrendingStores } from '@/hooks/trendingStores';
import { useSearchProducts, findProduct, SIZE, MAX_PAGE_SIZE } from '@/hooks/products';
import { dedupeProducts } from '@/hooks/helpers';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';

import './home.css';

const TRENDING_SEARCH_TERMS = ['perfume', 'suit', 'shirt', 'shoes'];

const Home = () => {
    const { variant, categories, source } = useAppState();
    const [products, setProducts] = useState([]);
    const trendingStores = useTrendingStores();
    useSearchProducts(TRENDING_SEARCH_TERMS, setProducts);
    const [from, setFrom] = useState(SIZE);

    const onClickLoadMoreSearch = async isScrollEvent => {
        if ((isScrollEvent || !loadingMore) && from / SIZE < MAX_PAGE_SIZE) {
            setLoadingMore(true);
            const productResults = await findProduct(TRENDING_SEARCH_TERMS, from);
            setFrom(productResults.from);
            setProducts(dedupeProducts(products, productResults.products));
            setLoadingMore(false);
        }
    };

    const [loadingMore, setLoadingMore] = useInfiniteScroll(onClickLoadMoreSearch);

    return (
        <>
            <Helmet>
                <title>{variant.title} | Home</title>
            </Helmet>
            <Header />
            <div id="amzn-assoc-ad-c92762b4-eb5b-4e4b-a7ec-45223dfee405"></div>
            <script
                async
                src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=c92762b4-eb5b-4e4b-a7ec-45223dfee405"
            ></script>
            <section className="content_section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">Trending Stores This Week</div>
                            <div className="trend_search">
                                <div className="row">
                                    {trendingStores.map((store, i) => (
                                        <div key={i} className="col">
                                            <StoreLink href={linkWithSource(store.link, source)} src={store.src} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <Products products={products} title="Trending products" />
                    </div>
                    {from / SIZE < MAX_PAGE_SIZE && (
                        <div className="row" onClick={onClickLoadMoreSearch}>
                            <div className="col-12">
                                <div className="more">{loadingMore ? 'Loading...' : 'Load More >'}</div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">Explore Popular Categories</div>
                            <div className="main_categories">
                                <div className="row m_row">
                                    {categories.map((category, i) => (
                                        <div key={i} className="col-6 col-md-3 m_col">
                                            <Category
                                                src={category.src}
                                                text={category.text}
                                                icon={category.icon}
                                                href={category.href}
                                                bgColor={category.bgColor}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className={classnames('update_section', {
                    'no-img': variant.showHeaderImage === false,
                })}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text">
                                Get Daily Updates on <br />
                                the Hottest Deals with
                                <br /> <a href="{variant.url}">{variant.humanUrl}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Home;
