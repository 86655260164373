import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';
import { AnalyticsProvider } from 'use-analytics';
import { AppProvider } from '@/context/AppContext';
import analytics from '@/analytics';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from '@/App';
import reportWebVitals from './reportWebVitals';
import Variants from './variants';

const variant = Variants[process.env.REACT_APP_BUILD_VARIANT || 'myshoppingfeed'];

Object.entries(variant.theme).forEach(([cssVarName, value]) => {
    document.querySelector(':root').style.setProperty(`--${cssVarName}`, value);
});

ReactDOM.render(
    <React.StrictMode>
        <AnalyticsProvider instance={analytics}>
            <AppProvider initialState={{ variant }}>
                <Router>
                    <App default />
                </Router>
            </AppProvider>
        </AnalyticsProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
